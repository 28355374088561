import Button2 from '../../components/Button/Button2'
import { MaximizeIcon, ThreeDotsIcon } from '../../components/Icons/Icons'
import Notebook from '../Notebook/Notebook'
import { Box, Fade, Grow, Menu, Tooltip } from '@mui/material'
import { ImageRender } from '../../components'
import SearchNew from '../ListFilter/SearchNew'
import React, { useEffect, useState } from 'react'
import { fetchPeople } from '../../store/People/Actions'
import { useDispatch } from 'react-redux'

const ResumeGrid = ({
  people,
  s3Obj,
  handleCloseProject,
  openImage,
  openPdf,
  handleDownloadCustomPdf,
  handleMove,
  handleMasterMappingDelete,
  showResumeEditor,
  isSuperUser
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [activeTab, setActiveTab] = useState(0)
  const [filteredResumes, setFilteredResumes] = useState([])
  const [notes, setNotes] = useState(people?.notes ?? [])
  const basicInfo = {
    name: people?.name,
    logo: people?.profile_picture_location,
    domain: 'Domain',
    location: 'Location',
    industry: 'Industry'
  }
  const resumes = people?.resumes || []
  const [searchQuery, setSearchQuery] = useState('')
  const dispatch = useDispatch()
  useEffect(() => {
    setFilteredResumes(resumes)
  }, [people])

  const handleSearch = (value) => {
    setSearchQuery(value)
    const filtered = resumes.filter((resume) => {
      return resume.proposal_name?.toLowerCase().includes(value.toLowerCase())
    })
    setFilteredResumes(filtered)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const refreshData = () => {
    const req = {
      keyword: '',
      page_num: 1,
      page_size: 10,
      tags: [],
      tag_dates: [],
      master_people_id: people?.id,
      isSingleResume: true
    }

    const callback = (payload) => {
      const { peopleList } = payload

      if (peopleList.length > 0) {
        console.log('peopleList 2', peopleList)
        setNotes(peopleList[0]?.notes ?? [])
      }
    }

    dispatch(fetchPeople(req, {}, callback))
  }

  return (
    <div className="w-full h-full">
      <div className="h-full">
        <div
          style={{
            width: '70%',
            margin: 'auto',
            padding: '20px',
            paddingBottom: '0px'
          }}
        >
          <div className="flex gap-2 items-center text-xxs text-grey-500 mb-3">
            <span className="cursor-pointer" onClick={handleCloseProject}>
              People
            </span>{' '}
            <span> {'>'} </span>{' '}
            <span className="text-grey-700 text-capitalize">
              {basicInfo.name}
            </span>
          </div>
          <div className="flex flex-col">
            <div className="flex gap-2 items-center">
              {basicInfo.logo ? (
                <img
                  src={basicInfo.logo}
                  style={{
                    width: '65px',
                    height: '65px',
                    objectFit: 'cover',
                    borderRadius: '10px',
                    border: '1px solid var(--grey-200)'
                  }}
                />
              ) : (
                <div
                  style={{
                    width: '50px',
                    height: '50px',
                    padding: '6px',
                    borderRadius: '10px',
                    border: '1px solid var(--grey-200)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'var(--grey-100)'
                  }}
                >
                  {basicInfo.name?.split(' ')?.[0]?.[0] +
                    basicInfo.name?.split(' ')?.[1]?.[0]}
                </div>
              )}

              <div className="">
                <p className="m-0 text-lg font-medium leading-5 flex items-center gap-1 text-capitalize">
                  {basicInfo.name}
                </p>
              </div>
              {isSuperUser && (
                <Button2
                  onClick={showResumeEditor}
                  secondary
                  style={{
                    marginLeft: 'auto'
                  }}
                >
                  Resume Editor
                </Button2>
              )}
            </div>
          </div>
          <div className="flex mt-3">
            <p
              className="m-0 font-medium text-sm p-1 px-3 text-grey-700 cursor-pointer"
              onClick={() => setActiveTab(0)}
              style={{
                borderBottom: activeTab === 0 && '2px solid var(--grey-700)'
              }}
            >
              Resumes
            </p>
            <p
              className="m-0 font-medium text-sm p-1 px-3 text-grey-700 cursor-pointer"
              onClick={() => {
                setActiveTab(1)
                refreshData()
              }}
              style={{
                borderBottom: activeTab === 1 && '2px solid var(--grey-700)'
              }}
            >
              Notes
            </p>
          </div>
        </div>

        <div
          className="bg-grey-100 p-4"
          style={{
            height: 'calc(100% - 200px)'
          }}
        >
          <div
            className="rounded-lg"
            style={{
              width: '70%',
              margin: 'auto',
              height: 'calc(100% - 20px)',
              overflowY: 'auto',
              backgroundColor: 'white',
              padding: '0 20px'
            }}
          >
            {activeTab === 0 && (
              <div
                style={{
                  padding: '10px 20px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    gap: '6px',
                    padding: '8px 0px',
                    marginBottom: '5px'
                  }}
                >
                  <SearchNew
                    value={searchQuery}
                    onChange={(value) => {
                      handleSearch(value)
                    }}
                    placeholder="Search Docs"
                    onClear={() => {
                      handleSearch('')
                    }}
                  />
                </div>
                <div className="">
                  {filteredResumes.length === 0 && (
                    <div className="flex justify-center items-center h-40">
                      <p className="text-grey-500">No resumes found</p>
                    </div>
                  )}

                  <div className="grid grid-col-5 gap-4">
                    {filteredResumes?.map((resume, index) => {
                      const {
                        proposal_id,
                        thumbnail_url = '',
                        screenshots = [],
                        page_number = 1,
                        proposal_name = '',
                        version = ''
                      } = resume
                      const coverImage = _.isEmpty(screenshots)
                        ? thumbnail_url
                        : screenshots[0]?.src
                      const imagePayload = _.isEmpty(screenshots)
                        ? thumbnail_url
                        : screenshots
                      const displayName = proposal_name || proposal_id

                      return (
                        <Box
                          key={index}
                          className="parent-card"
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            border: '1px solid var(--grey-200)',
                            overflow: 'hidden',
                            borderRadius: '10px',
                            '&:hover img': {
                              opacity: '0.5'
                            },
                            '&:hover #overlayMenu': {
                              opacity: '0.8 !important'
                            }
                          }}
                        >
                          <Box
                            id={'project_' + index}
                            key={'project_' + index}
                            style={{
                              padding: '10px',
                              position: 'relative'
                            }}
                          >
                            <Box
                              sx={{
                                justifyContent: 'center',
                                width: '100%',
                                display: 'flex',
                                cursor: 'pointer'
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <ImageRender
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    minWidth: '180px',
                                    maxWidth: '200px',
                                    minHeight: '220px',
                                    objectFit: 'contain',
                                    overflow: 'hidden',
                                    transition: 'opacity 0.5s ease'
                                  }}
                                  src={coverImage}
                                  s3Obj={s3Obj}
                                  effect="blur"
                                  overlay={
                                    <Box
                                      id="overlayMenu"
                                      style={{
                                        position: 'absolute',
                                        top: '0%',
                                        right: '0%',
                                        opacity: 0,
                                        backgroundColor: 'var(--grey-700)',
                                        padding: '8px',
                                        width: '100%',
                                        height: '100%',
                                        boxSizing: 'border-box',
                                        zIndex: 1000,
                                        transition: 'opacity 0.3s ease'
                                      }}
                                    >
                                      <div className="relative h-full">
                                        {coverImage && (
                                          <div
                                            className="absolute"
                                            style={{
                                              top: '45%',
                                              left: '45%'
                                            }}
                                            onClick={() => {
                                              openImage(imagePayload)
                                            }}
                                          >
                                            <MaximizeIcon className="size-6 text-grey-100" />
                                          </div>
                                        )}
                                      </div>
                                    </Box>
                                  }
                                  fallback={
                                    <>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                          wordBreak: 'break-word'
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            fontWeight: '600',
                                            fontSize: '16px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            '-webkit-line-clamp': 2,
                                            display: '-webkit-box',
                                            '-webkit-box-orient': 'vertical'
                                          }}
                                        >
                                          {displayName}
                                        </Box>
                                      </Box>
                                    </>
                                  }
                                />
                              </Box>
                            </Box>
                          </Box>
                          <div className="bg-grey-100 p-3 flex justify-between items-center">
                            {proposal_name ? (
                              <Tooltip title={proposal_name}>
                                <p
                                  className="m-0 font-medium text-xs cursor-pointer"
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                  }}
                                  onClick={() => {
                                    openPdf(proposal_id, page_number)
                                  }}
                                >
                                  {proposal_name}
                                </p>
                              </Tooltip>
                            ) : (
                              <p className="m-0 font-medium text-sm">
                                Untitled Project
                              </p>
                            )}

                            <div className="relative h-full w-16 card-options">
                              <div className="text-grey-700 rounded-md flex justify-end">
                                <span
                                  onClick={handleClick}
                                  className="cursor-pointer grid place-content-center"
                                >
                                  <ThreeDotsIcon
                                    className="size-5"
                                    strokeWidth={2}
                                  />
                                </span>
                                <Menu
                                  elevation={2}
                                  TransitionComponent={
                                    Grow
                                    // style={{
                                    //   // transformOrigin from center
                                    //   transformOrigin: '10px 10px'
                                    // }}
                                  }
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleCloseMenu}
                                  MenuListProps={{
                                    'aria-labelledby': 'basic-button'
                                  }}
                                  className="p-1"
                                >
                                  <Button2
                                    noOutline
                                    secondary
                                    style={{
                                      width: '100%'
                                    }}
                                    onClick={() =>
                                      handleDownloadCustomPdf(
                                        proposal_id,
                                        page_number
                                      )
                                    }
                                  >
                                    Download Resume
                                  </Button2>
                                  <Button2
                                    noOutline
                                    secondary
                                    style={{
                                      width: '100%'
                                    }}
                                    onClick={() => handleMove(resume)}
                                  >
                                    Move Resume
                                  </Button2>
                                  <Button2
                                    noOutline
                                    secondary
                                    style={{
                                      width: '100%'
                                    }}
                                    onClick={() => {
                                      handleMasterMappingDelete(
                                        people,
                                        index,
                                        'delete'
                                      )
                                    }}
                                  >
                                    Remove Resume
                                  </Button2>
                                </Menu>
                              </div>
                            </div>
                          </div>
                        </Box>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}

            {activeTab === 1 && (
              <Notebook
                notesData={notes}
                refresh={() => {
                  refreshData()
                }}
                source={{
                  id: people?.id,
                  type: 'people',
                  name: people?.name
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResumeGrid

import React from 'react'
import Loader from '../Loader'
import { Pagination } from '@mui/material'

const HeadlessTable = ({
  columns,
  data,
  meta,
  loading,
  pageLoading,
  onRowClick,
  leftAlign,
  handleSelectedData,
  selectedData,
  fetchPage
}) => {
  console.log('meta 2', meta, loading)
  return (
    <div className="border-grey-200 rounded-lg relative">
      <div
        className={`flex gap-2 p-2 px-4 bg-grey-100 text-xs sticky top-0 z-10 font-medium border-b-1 ${
          leftAlign ? 'text-left' : 'text-center'
        } `}
      >
        {columns.map((column, index) => {
          return (
            <div className="w-full flex" key={index}>
              {index === 0 && (
                <div
                  className="flex items-center h-full cursor-pointer py-1 mr-2"
                  onClick={(e) => {
                    e.stopPropagation()
                    // handleSelectedData(row)
                  }}
                >
                  <input
                    type="checkbox"
                    className={`cursor-pointer checkbox-ui border-grey-200 rounded-md w-3 h-3 invisible`}
                    // checked={selectedData?.includes(row?.id)}
                  />
                </div>
              )}
              {column.label}
            </div>
          )
        })}
      </div>
      {loading ? (
        <div
          className="grid place-content-center "
          style={{ height: 'calc(90vh - 100px)' }}
        >
          Loading Data... <Loader />
        </div>
      ) : data?.length > 0 ? (
        <>
          <div
            className="grid text-center"
            id="rows"
            // style={{ height: 'calc(90vh - 100px)' }}
          >
            {data?.map((row, index) => {
              return (
                <div
                  className={`flex gap-2 px-4 text-center text-sm border-b-1 p-2 row text-grey-600 ${
                    row?.checked ? 'bg-grey-100' : ''
                  }
                ${leftAlign ? 'text-left' : 'text-center'}
                `}
                  style={{ height: 'fit-content' }}
                  key={index}
                  onClick={() => onRowClick(row)}
                >
                  {/* <div className="font-medium w-full flex items-center gap-2 text-capitalize">
                  
                 
                </div> */}

                  {columns.map((column, index) => {
                    return (
                      <div
                        className={`w-full flex items-center text-xs ${
                          leftAlign ? 'text-left' : 'text-center'
                        }
                `}
                        key={index}
                        style={column.style}
                      >
                        {index === 0 && (
                          <div
                            className="flex items-center h-full cursor-pointer py-1 mr-2"
                            onClick={(e) => {
                              e.stopPropagation()
                              handleSelectedData(row)
                            }}
                          >
                            <input
                              type="checkbox"
                              className={`cursor-pointer checkbox-ui border-grey-200 rounded-md w-3 h-3  ${
                                selectedData?.includes(row?.id)
                                  ? 'bg-grey-700'
                                  : ''
                              }`}
                              checked={selectedData?.includes(row?.id)}
                            />
                          </div>
                        )}
                        {column.children
                          ? column.children(row)
                          : row[column.accessorKey] ?? '-'}
                      </div>
                    )
                  })}
                </div>
              )
            })}
            {pageLoading && (
              <div
                className="grid place-content-center"
                style={{
                  height: 'calc(90vh - 100px)'
                }}
              >
                <Loader
                  loading={pageLoading}
                  caption={'Loading More Data'}
                  flex
                />
              </div>
            )}
          </div>
          <div className="sticky bottom-0 right-0 w-full bg-white border-box p-3">
            <Pagination
              page={meta?.page}
              count={Math.ceil(meta?.totalCount / meta?.pageSize)}
              shape="rounded"
              onChange={(e, page) => {
                console.log('page', page)

                fetchPage(page)
              }}
            />
          </div>
        </>
      ) : (
        <div className="grid place-content-center " style={{ height: '50vh' }}>
          No Data found
        </div>
      )}
    </div>
  )
}

export default HeadlessTable

import React, { useEffect, useState, useMemo } from 'react'
import { Container, Section, SectionFixed } from '../../components/Container'
import { useSelector, useDispatch } from 'react-redux'
import { ROUTES } from '../../config/routes'
import { useNavigate, useLocation } from 'react-router-dom'
import LibraryCard from '../../sections/LibraryCard'
import {
  fetchLibrary,
  clearLibraryDocument,
  setActiveTag,
  fetchCollections,
  updateLibraryDocument,
  updateLibrarySuggestion,
  deleteLibraryDocument
} from '../../store/Library/Actions'
import { useStyles } from './styles'
import _ from 'lodash'
import EmptyRecord from '../../sections/EmptyRecords'
import {
  Loader,
  MenuButton,
  ImageGallery,
  Button,
  useConfirmation
} from '../../components'
import ListFilter from '../../sections/ListFilter'
import CollectionList from '../../pages/Library/LibraryCollectionList'
import InfiniteScroll from 'react-infinite-scroll-component'
import AddToLibrary from '../AddToLibrary'
import { initalizeS3 } from '../../utils/AWS'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { copyHTMLContent } from '../../utils/CopyHTML'
import Variations from '../../sections/TextResult/Variations'
import ReactResizeDetector from 'react-resize-detector'
import clsx from 'clsx'
import moment from 'moment'
import { ReactComponent as SortDown } from '../../assets/svg/SortDown.svg'
import { ReactComponent as SortUp } from '../../assets/svg/SortUp.svg'
import {
  Box,
  Grid,
  Tooltip,
  Skeleton,
  SvgIcon,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useColor } from '../../ThemeContext'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import ReactDOM from 'react-dom/client'
import FavoriteIcon from '@mui/icons-material/Favorite'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ContentCopyIcon from '@mui/icons-material/ContentCopyOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import AddToCollection from '../../sections/LibraryCard/AddToCollection'
import { UPDATE_LIBRARY_VARIAITONS } from '../../store/actionTypes'
import { fetchLibraryVariations } from '../../store/api'
import AddIcon from '@mui/icons-material/Add'
import { checkUserRoleViewer } from '../../utils/User'
import {
  GridSort,
  HideColumn,
  ViewMode,
  ResizableCell
} from '../../components/TableActions'

const formatHeader = (header) => {
  if (header === 'document_name') {
    return 'Title'
  } else if (header === 'created_by_user') {
    return 'Created By'
  } else if (header === 'created_at') {
    return 'Created On'
  } else if (header === 'number_of_views') {
    return 'Views'
  }
  return _.startCase(header)
}

const getConfigFromLocal = () => {
  let data = localStorage.getItem('knowledgeConfigMap')
  data = data ? JSON.parse(data) : null
  return data
}

const LibraryList = (props) => {
  const { isFocused = false } = props
  const { state = {} } = useLocation()
  const classes = useStyles()
  const config = getConfigFromLocal()
  const {
    columnWidths: configColumnWidths,
    hiddenColumns: configHiddenColumns,
    viewMode: configViewMode,
    sortOrder: configSortOrder
  } = config || {}
  const libraryState = useSelector((state) => state.library)
  const {
    libraryList = [],
    activeTag = '',
    currentPage,
    libraryCollections = {},
    has_more = null,
    search: searchKey
  } = libraryState
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { id: userId, domain_id = '' } = domain || {}
  const [loading, setLoading] = useState(true)
  const { LIBRARY_KNOWLEDGE } = ROUTES
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [keyword, setKeyword] = useState('')
  const [keywordState, setKeywordState] = useState('')
  const { public_collections = [], private_collections = [] } =
    libraryCollections || {}
  const [active, setActive] = useState('')
  const [page, setPage] = useState(1)
  const hasMoreData = has_more
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [showAddToLibrary, setShowAddToLibrary] = useState(false)
  const [suggestedData, setSuggestedData] = useState({})
  const [selectedImages, setSelectedImages] = useState([])
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [s3Obj, sets3Obj] = useState({})
  const [showVariations, setShowVariations] = useState({})
  const [viewMode, setViewMode] = useState(configViewMode || 'grid')
  const [columnWidths, setColumnWidths] = useState(configColumnWidths || {})
  const [columns, setColumns] = useState([
    'document_name',
    'collections',
    'created_by_user',
    'created_at',
    'number_of_views',
    'updated_at'
  ])
  const [hiddenColumns, setHiddenColumns] = useState(configHiddenColumns || [])
  const [visibleColumns, setVisibleColumns] = useState([
    'document_name',
    'collections',
    'created_by_user',
    'created_at',
    'number_of_views',
    'updated_at'
  ])
  const [sortOrder, setSortOrder] = useState(
    configSortOrder || {
      created_at: 'desc'
    }
  )
  const [tableLoading, setTableLoading] = useState(false)
  const [fetchingData, setFetchingData] = useState(false)
  const [isResizing, setIsResizing] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [isMenuHovering, setIsMenuHovering] = useState(false)
  const visibleColumnPercentageTotal = useMemo(() => {
    let total = 0
    ;[...visibleColumns, 'menu_button'].forEach((column) => {
      total += columnWidths[column]
    })
    return total
  }, [columnWidths, visibleColumns])
  const { selectedColor } = useColor()
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const [showAddToCollections, setShowAddToCollections] = useState(false)
  const [searchControllers, setSearchControllers] = useState({})
  const [variationControllers, setVariationControllers] = useState({})

  const isUserViewer = checkUserRoleViewer()

  const fetchAllVariations = async (libraryList) => {
    libraryList.forEach((item) => {
      const { library_id } = item
      if (!Object.keys(variationControllers).includes(library_id)) {
        fetchSimilarities(library_id)
      }
    })
  }

  const fetchSimilarities = async (id) => {
    if (id) {
      const newController = new AbortController()
      setVariationControllers((prevControllers) => ({
        ...prevControllers,
        [id]: newController
      }))
      const res = await fetchLibraryVariations(
        { id, domain_id },
        { signal: newController.signal }
      )
      if (res.status === 200) {
        const { resultsets = [] } = res?.data || {}
        dispatch({
          type: UPDATE_LIBRARY_VARIAITONS,
          payload: {
            [id]: resultsets
          }
        })
      }
    }
  }

  const clearController = () => {
    Object.keys(variationControllers).forEach((key) => {
      const cancelRequest = variationControllers[key]
      cancelRequest.abort()
    })
    setVariationControllers({})
    Object.keys(searchControllers).forEach((key) => {
      const cancelRequest = searchControllers[key]
      cancelRequest.abort()
    })
    setSearchControllers({})
  }

  useEffect(() => {
    if (isFocused && searchKey) {
      setKeyword(searchKey)
      setKeywordState(searchKey)
    }
  }, [searchKey, isFocused])

  useEffect(() => {
    if (!showAddToLibrary) {
      setSuggestedData({})
    }
  }, [showAddToLibrary])

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    initalizeData()
  }, [])

  const clearSearch = () => {
    setKeyword('')
    setKeywordState('')
    setPage(1)
  }

  useEffect(() => {
    if (isFocused) {
      if (activeTag?.visibility === 'suggestion') {
        setShowSuggestions(true)
      } else {
        setShowSuggestions(false)
      }
      clearSearch()
      setPage(1)
      fetchData(1, activeTag)
    }
  }, [activeTag, isFocused])

  const handleOnClickEditor = (id) => {
    if (id) {
      trackEvent(mixpanelEvents.KNOWLEDGE_CONTENT_OPENED, 'SUCCESS', {}, {})
      const path = `${LIBRARY_KNOWLEDGE}?libraryId=${id}`
      const callback = navigate(path, {
        state: { newDocument: true }
      })
      dispatch(clearLibraryDocument(callback))
    }
  }

  const checkHorizontalScrollbar = (target) => {
    const element = document.getElementById(target)
    return element ? element.scrollHeight !== element.clientHeight : false
  }

  useEffect(() => {
    if (libraryList) {
      setLoading(false)
      const hasScroll = checkHorizontalScrollbar('scrollableDiv')
      if (!hasScroll && hasMoreData && isFocused && !fetchingData) {
        navigateToPage()
      }
    } else {
      setLoading(true)
    }
  }, [libraryList, fetchingData, viewMode])

  useEffect(() => {
    if (isFocused) {
      if (!libraryList) {
        fetchData()
      }
    }
  }, [isFocused])

  const initColumnsWidth = (visibleColumns) => {
    if (visibleColumns.length === 0) return
    const newWidths = columnWidths
    visibleColumns = [...visibleColumns, 'menu_button']
    visibleColumns.forEach((item) => {
      if (!Object.keys(columnWidths).includes(item)) {
        if (item === 'document_name') {
          newWidths[item] = 20
        } else if (item === 'menu_button') {
          newWidths[item] = 5
        } else {
          newWidths[item] = 8
        }
      }
    })
    if (!_.isEmpty(newWidths)) setColumnWidths(newWidths)
  }

  const saveConfigToLocal = () => {
    const dataMap = {
      columnWidths,
      hiddenColumns,
      viewMode,
      sortOrder
    }
    localStorage.setItem('knowledgeConfigMap', JSON.stringify(dataMap))
  }

  useEffect(() => {
    saveConfigToLocal()
  }, [viewMode, columnWidths, hiddenColumns, sortOrder])

  useEffect(() => {
    if (isFocused) {
      const visibleColumns = columns.filter(
        (item) => !hiddenColumns.includes(item)
      )
      setVisibleColumns(visibleColumns)
      initColumnsWidth(visibleColumns)
    }
  }, [hiddenColumns, columns, isFocused])

  const fetchData = (
    page = 1,
    currentActive = activeTag,
    search = keyword,
    currentSortOrder = sortOrder
  ) => {
    setFetchingData(true)
    if (page === 1) {
      setTableLoading(true)
      clearController()
    }
    const newController = new AbortController()
    setSearchControllers((prevControllers) => ({
      ...prevControllers,
      [page]: newController
    }))
    const data = {
      domain_id,
      page_num: page,
      sort_order: currentSortOrder
    }
    if (currentActive) {
      data.collection = currentActive.id
      data.collectionType = currentActive?.visibility
    }
    if (search) {
      data.keyword = search
    }
    const callback = (results) => {
      if (results) {
        // fetchAllVariations(results)
        setTableLoading(false)
        setFetchingData(false)
      }
    }
    dispatch(fetchLibrary(data, callback, { signal: newController.signal }))
  }

  const navigateToPage = () => {
    fetchData(page + 1)
    setPage(page + 1)
  }

  const handleSearch = (key) => {
    setKeyword(key)
  }

  const searchProposal = (event) => {
    if (event?.keyCode === 13) {
      setPage(1)
      setKeywordState(keyword)
      fetchData(1, activeTag, keyword)
    }
  }

  const setCurrentCollection = (tag) => {
    const currentActive = _.find(
      [...public_collections, ...private_collections],
      { name: tag }
    )
    dispatch(setActiveTag(currentActive))
  }

  const resetFilter = () => {}

  if (loading) {
    return (
      <Container>
        <Section>
          <div
            style={{
              height: '100%',
              width: '100',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Loader loading={loading} caption="loading all items" />
          </div>
        </Section>
      </Container>
    )
  }

  const handleDrag = (event, data) => {
    event.dataTransfer.setData('data', JSON.stringify(data))
    const elem = document.createElement('div')
    elem.id = 'drag-ghost'
    const { document_name } = data
    const name = cleanMarkTags(document_name)
    const jsxContent = (
      <Box
        sx={{
          background: '#DBEAFF',
          display: 'flex',
          alignItems: 'center',
          width: '300px',
          position: 'absolute',
          top: '-9999px',
          padding: '10px',
          gap: '10px',
          fontSize: '14px',
          color: '#797979',
          '& .MuiSvgIcon-root': {
            fontSize: '30px !important'
          }
        }}
      >
        <Box>{name}</Box>
      </Box>
    )
    const root = ReactDOM.createRoot(elem)
    root.render(jsxContent)
    document.body.appendChild(elem)
    event.dataTransfer.setDragImage(elem, 0, 0)
  }

  const handleDragEnd = () => {
    setActive('')
  }

  const suggestionAction = (val, isAccepted) => {
    const { id } = val
    const data = {
      id,
      status: isAccepted
    }
    if (isAccepted) {
      setSuggestedData({
        ...val,
        htmlString: val?.document_html,
        title: val?.document_name,
        metaData: {
          ...val?.metadata_,
          src: 'suggestion_accepted'
        }
      })
      setShowAddToLibrary(true)
    } else {
      dispatch(updateLibrarySuggestion(data))
    }
  }

  const onCreateCallback = (data) => {
    dispatch(updateLibrarySuggestion(data))
  }

  const handleVariation = (id) => {
    setShowVariations({ ...showVariations, [id]: !showVariations[id] })
  }

  const handleCopy = async (content = '', title = '') => {
    const newContent = `<html><body><b>${title}</b><br>${content}</body></html>`
    copyHTMLContent(newContent)
  }

  const handleSort = (column, order) => {
    const newOrder = _.cloneDeep(sortOrder)
    if (newOrder?.[column] && newOrder[column] === order) {
      delete newOrder[column]
    } else {
      newOrder[column] = order
    }
    setSortOrder(newOrder)
    setPage(1)
    fetchData(1, activeTag, keyword, newOrder)
  }

  const getColWidth = (width, key) => {
    if (width) {
      return width + '%'
    } else return 'auto'
  }

  const TableLoader = ({ count, viewMode, hideHeader = false }) => {
    return viewMode === 'table' ? (
      <table
        className={classes.tableWrapper}
        style={{ width: visibleColumnPercentageTotal + '%' }}
      >
        {!hideHeader && (
          <thead>
            <tr>
              {visibleColumns.map((column, index) => {
                const width = getColWidth(columnWidths[column], column)
                return (
                  <th
                    key={index}
                    style={{ width }}
                    className={classes.tableHeaderLoader}
                  >
                    <Skeleton
                      variant="rectangular"
                      key={index}
                      height={25}
                      sx={{ marginBottom: '5px' }}
                    />
                  </th>
                )
              })}
            </tr>
          </thead>
        )}
        <tbody>
          {[...Array(count)].map((item, index) => (
            <tr key={index}>
              {visibleColumns.map((column, index) => {
                const width = getColWidth(columnWidths[column], column)
                return (
                  <td
                    key={index}
                    className={classes.tableRowLoader}
                    style={{ width }}
                  >
                    <Skeleton
                      variant="rectangular"
                      key={index}
                      width={'100%'}
                      height={25}
                      sx={{ marginBottom: '5px' }}
                    />
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <Box
        sx={{
          display: 'flex',
          margin: '15px 20px',
          borderRadius: '4px',
          flexWrap: 'wrap',
          gap: '10px',
          justifyContent: 'space-between'
        }}
      >
        {[...Array(count / 5)].map((item, index) => (
          <Skeleton
            variant="rectangular"
            key={index}
            width={'100%'}
            height={'150px'}
            sx={{ marginBottom: '5px' }}
          />
        ))}
      </Box>
    )
  }

  const GetSortIcon = ({ id, isSelected, order }) => {
    return (
      <Box className={classes.sortLabel}>
        <IconButton
          disableRipple
          disabled={tableLoading}
          onClick={() => {
            handleSort(id, 'asc')
          }}
        >
          <SvgIcon
            className={
              isSelected && order === 'asc'
                ? classes.sortIconActive
                : classes.sortIcon
            }
          >
            <SortUp />
          </SvgIcon>
        </IconButton>
        <IconButton
          disableRipple
          disabled={tableLoading}
          onClick={() => {
            handleSort(id, 'desc')
          }}
        >
          <SvgIcon
            className={
              isSelected && order === 'desc'
                ? classes.sortIconActive
                : classes.sortIcon
            }
          >
            <SortDown />
          </SvgIcon>
        </IconButton>
      </Box>
    )
  }

  const handlePDFViewer = (data) => {
    const { proposal_id, document_id, page_number } = data
    const id = proposal_id || document_id
    const page = page_number || 0
    const path = `/view/pdf/${id}/${page}/${true}`
    window.open(path, '_blank')
  }

  const handleTagClick = (e, tag) => {
    e.stopPropagation()
    if (setCurrentCollection) setCurrentCollection(tag)
  }

  const renderKeyValues = (key, value, rowLoading) => {
    if (key === 'created_at' || key === 'updated_at')
      return moment(value).format('MMM D, YYYY')
    else if (key === 'document_name') {
      return cleanMarkTags(value)
    } else if (key === 'collections') {
      const collectionPublic = []
      const collectionPrivate = []
      Object.keys(value || {}).forEach((key) => {
        const { name, visibility, visible_users } = value[key]
        if (visibility === 'public') collectionPublic.push(name)
        else if (visibility === 'private' && visible_users.includes(userId)) {
          collectionPrivate.push(name)
        }
      })

      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
          {collectionPublic.map((collection, index) => (
            <Box
              key={index}
              sx={{
                wordBreak: 'break-word',
                padding: '2px 5px',
                color: selectedColor,
                border: '1px solid',
                borderColor: selectedColor,
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center'
              }}
              onClick={(e) => !rowLoading && handleTagClick(e, collection)}
            >
              <Tooltip title={'Visible to all'}>
                <LanguageOutlinedIcon
                  sx={{ strokeWidth: 1, stroke: '#ffffff', fontSize: '14px' }}
                />
              </Tooltip>
              <Box
                variant={'span'}
                sx={{ fontSize: '12px', fontStyle: 'normal' }}
              >
                &nbsp;{collection}
              </Box>
            </Box>
          ))}
          {collectionPrivate.map((collection, index) => (
            <Box
              key={index}
              sx={{
                wordBreak: 'break-word',
                padding: '4px 6px',
                color: selectedColor,
                border: '1px solid',
                borderColor: selectedColor,
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center'
              }}
              onClick={(e) => !rowLoading && handleTagClick(e, collection)}
            >
              <Tooltip title={'Visible to only you'}>
                <LockOutlinedIcon
                  sx={{ strokeWidth: 1, stroke: '#ffffff', fontSize: '14px' }}
                />
              </Tooltip>
              <Box
                variant={'span'}
                sx={{ fontSize: '12px', fontStyle: 'normal' }}
              >
                &nbsp;{collection}
              </Box>
            </Box>
          ))}
        </Box>
      )
    } else return value
  }

  const handleResize = (column, width) => {
    if (width > 10) {
      setColumnWidths({
        ...columnWidths,
        [column]: width
      })
    }
  }

  const handleSortColumnClick = (column) => {
    const order =
      sortOrder[column] === 'asc'
        ? 'desc'
        : sortOrder[column] === 'desc'
        ? ''
        : 'asc'
    handleSort(column, order)
  }

  const resetView = () => {
    setViewMode('grid')
    setHiddenColumns([])
    setColumnWidths({})
    const defaultSortOrder = {
      created_at: 'desc'
    }
    setSortOrder(defaultSortOrder)
    setPage(1)
    fetchData(1, activeTag, keyword, defaultSortOrder)
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleMenuOpen = (row) => {
    const { id } = row || {}
    if (id) handleOnClickEditor(id)
  }

  const handleMenuCopy = (row) => {
    const { document_name = '', document_html = '' } = row || {}
    handleCopy(document_html, document_name)
  }

  const cleanMarkTags = (htmlString) => {
    return htmlString.replace(/<\/?[^>]+(>|$)/g, '')
  }

  const handleMenuDelete = (row) => {
    const { id, collections = {}, document_name } = row
    if (id) {
      const req = {
        id,
        collections
      }
      const name = cleanMarkTags(document_name)
      showConfirmDialog({
        onConfirm: async () => {
          dispatch(deleteLibraryDocument(req))
        },
        confirmationMessageTitle: `Are you sure you want to delete the knowledge "${name}" ?`,
        confirmationMessage:
          'This action will also remove the knowledge from all the collections.'
      })
    }
  }

  const handleAddToCollections = (row) => {
    setShowAddToCollections(row)
  }

  const menuOptions = [
    {
      label: 'Open',
      icon: <OpenInNewIcon />,
      onClick: handleMenuOpen
    },
    {
      label: 'Add to Collections',
      icon: <FavoriteIcon />,
      onClick: handleAddToCollections
    },
    {
      label: 'Copy',
      icon: <ContentCopyIcon />,
      onClick: handleMenuCopy
    },
    {
      label: 'Delete',
      icon: <DeleteIcon />,
      onClick: handleMenuDelete
    }
  ]

  const handleSuggestionAccept = (row) => {
    suggestionAction(row, true)
  }

  const handleSuggestionReject = (row) => {
    suggestionAction(row, false)
  }

  const menuOptionsSuggestions = [
    {
      label: 'Add to Library',
      icon: <AddIcon />,
      onClick: handleSuggestionAccept
    },
    {
      label: 'Ignore Suggestion',
      icon: <DeleteIcon />,
      onClick: handleSuggestionReject
    },
    {
      label: 'Copy',
      icon: <ContentCopyIcon />,
      onClick: handleMenuCopy
    }
  ]

  const handleAddToNewCollections = (newValues, newCollections) => {
    const { id } = showAddToCollections || {}
    if (id) {
      const params = {
        id,
        collections: newValues,
        collectionValues: newCollections
      }
      dispatch(updateLibraryDocument(params))
    }
    setShowAddToCollections(false)
  }

  const renderAddToCollections = () => {
    const { collections } = showAddToCollections || {}
    return (
      <Dialog
        open={showAddToCollections}
        onClose={() => setShowAddToCollections(false)}
      >
        <DialogTitle>Add to Collections</DialogTitle>
        <DialogContent>
          <AddToCollection
            view={'dropdown'}
            collections={collections}
            onSelect={handleAddToNewCollections}
            disabled={isUserViewer}
          />
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Container>
      <SectionFixed>
        <ListFilter
          disabled={tableLoading}
          isShowFilter={false}
          filterData={[]}
          filter={() => {}}
          refresh={false}
          resetFilter={resetFilter}
          searchValue={keyword}
          handleSearch={handleSearch}
          searchOnEnter={searchProposal}
          selectedFilter={[]}
          setSelectedFilter={() => {}}
          pageType="library"
          isEditable={!isUserViewer}
          afterSearchElement={
            <Box className={classes.searchButtonWrapper}>
              <ViewMode
                disabled={tableLoading}
                viewMode={viewMode}
                setViewMode={setViewMode}
              />
              <ToggleButtonGroup
                onClick={() => resetView()}
                sx={{ height: '28px', marginLeft: '5px' }}
              >
                <Tooltip title="Reset">
                  <ToggleButton value="grid" aria-label="left aligned">
                    <RestartAltIcon
                      sx={{ padding: '1px 6px', fontSize: '18px' }}
                    />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
              {viewMode === 'grid' && (
                <GridSort
                  disabled={tableLoading}
                  sortOrder={sortOrder}
                  columns={visibleColumns.filter(
                    (item) =>
                      !hiddenColumns.includes(item) && item !== 'collections'
                  )}
                  formatHeader={formatHeader}
                  GetSortIcon={GetSortIcon}
                  handleColumnClick={handleSortColumnClick}
                />
              )}
              {viewMode === 'table' && (
                <HideColumn
                  visibleColumns={visibleColumns}
                  disabled={tableLoading}
                  columns={columns}
                  setColumns={setColumns}
                  hiddenColumns={hiddenColumns}
                  setHiddenColumns={setHiddenColumns}
                  defaultHidden={[]}
                  viewMode={viewMode}
                  formatHeader={formatHeader}
                />
              )}
            </Box>
          }
        />
      </SectionFixed>
      <Section overFlow>
        <Grid
          container
          sx={{
            height: '100%',
            width: '100%',
            justifyContent: 'center'
          }}
        >
          <Grid
            item
            lg={3}
            md={4}
            sm={5}
            xs={12}
            xl={2}
            sx={{
              height: '100%',
              borderRight: '1px solid #E5E5E5'
            }}
          >
            <Container>
              <Section overFlow>
                <CollectionList active={active} setActive={setActive} />
              </Section>
            </Container>
          </Grid>
          <Grid
            item
            lg
            sm
            md
            xs
            xl
            sx={{
              height: '100%'
            }}
          >
            <Box
              id="scrollableDiv"
              sx={{ height: '100%', width: '100%', overflow: 'auto' }}
            >
              {tableLoading ? (
                <TableLoader viewMode={viewMode} count={30} />
              ) : (
                <InfiniteScroll
                  dataLength={libraryList?.length ? libraryList.length : 0}
                  next={() => navigateToPage()}
                  hasMore={hasMoreData}
                  loader={
                    fetchingData && (
                      <TableLoader viewMode={viewMode} count={10} hideHeader />
                    )
                  }
                  scrollableTarget="scrollableDiv"
                >
                  <Container>
                    <Section overFlow>
                      {viewMode === 'grid' ? (
                        _.isArray(libraryList) && (
                          <div className="grid grid-col-1 gap-3 p-3">
                            {libraryList.map((el, index) => {
                              const { id, status = false } = el
                              const rowLoading = !!status
                              return (
                                <Box
                                  key={index}
                                  sx={{
                                    background: rowLoading ? 'inherit' : '',
                                    opacity: rowLoading ? '0.38' : 1,
                                    cursor: rowLoading ? 'inherit' : 'pointer'
                                  }}
                                >
                                  <LibraryCard
                                    setCurrentCollection={setCurrentCollection}
                                    key={index}
                                    data={el}
                                    index={index}
                                    onClick={
                                      showSuggestions
                                        ? null
                                        : handleOnClickEditor
                                    }
                                    searchKeyword={keywordState}
                                    enableDrag={true}
                                    onDrag={handleDrag}
                                    onDragEnd={handleDragEnd}
                                    userId={userId}
                                    showVariations={showVariations?.[id]}
                                    variationsLabel="Variations"
                                    handleVariation={handleVariation}
                                    isSuggestion={showSuggestions}
                                    onSuggestion={suggestionAction}
                                    selectedImages={selectedImages}
                                    setSelectedImages={setSelectedImages}
                                    setSelectedImageIndex={
                                      setSelectedImageIndex
                                    }
                                    s3Obj={s3Obj}
                                    disabled={rowLoading}
                                  />
                                  {showVariations?.[id] && (
                                    <Variations
                                      domain_id={domain_id}
                                      handleCopy={handleCopy}
                                      variationData={el}
                                      setShowVariations={handleVariation}
                                      showVariations={showVariations?.[id]}
                                      handlePDFViewer={handlePDFViewer}
                                      s3Obj={s3Obj}
                                      disableEdit={false}
                                      src={'search'}
                                      wrapperPadding={'40px'}
                                    />
                                  )}
                                </Box>
                              )
                            })}
                            {libraryList?.length === 0 &&
                              !fetchingData &&
                              !tableLoading && (
                                <Box
                                  className={classes.tableRowText}
                                  sx={{ padding: '15px' }}
                                >
                                  <EmptyRecord
                                    displayMessage={
                                      'No knowledge article found'
                                    }
                                  />
                                </Box>
                              )}
                          </div>
                        )
                      ) : (
                        <table
                          className={classes.tableWrapper}
                          style={{ width: visibleColumnPercentageTotal + '%' }}
                        >
                          <ReactResizeDetector handleWidth>
                            {({ width }) => (
                              <>
                                <colgroup>
                                  {[...visibleColumns, 'menu_button'].map(
                                    (column) => {
                                      const resizeWidth = getColWidth(
                                        columnWidths[column],
                                        column
                                      )
                                      return (
                                        <col
                                          key={column}
                                          style={{ width: resizeWidth }}
                                        />
                                      )
                                    }
                                  )}
                                </colgroup>
                                <thead>
                                  <tr>
                                    {visibleColumns.map((key) => (
                                      <ResizableCell
                                        parentWidth={width}
                                        key={key}
                                        onResize={(width) =>
                                          handleResize(key, width)
                                        }
                                        width={getColWidth(
                                          columnWidths[key],
                                          key
                                        )}
                                        id={key}
                                        isResizing={isResizing}
                                        setIsResizing={setIsResizing}
                                        isHovering={isHovering}
                                        setIsHovering={setIsHovering}
                                        tableComponent="header"
                                      >
                                        <Box
                                          className={classes.tableHeaderText}
                                        >
                                          <Box>
                                            {key && formatHeader(key || '')}
                                          </Box>
                                          {key !== 'collections' && (
                                            <GetSortIcon
                                              id={key}
                                              isSelected={Object.keys(
                                                sortOrder
                                              ).includes(key)}
                                              order={sortOrder[key]}
                                            />
                                          )}
                                        </Box>
                                      </ResizableCell>
                                    ))}
                                    <th
                                      className={clsx(
                                        classes.tableHeader,
                                        classes.menuButton
                                      )}
                                    >
                                      <Box className={classes.tableHeaderText}>
                                        <Box> &nbsp;</Box>
                                      </Box>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {_.isArray(libraryList) && (
                                    <>
                                      {libraryList.map((el, index) => {
                                        const { id, status = false } = el
                                        const rowLoading = !!status
                                        const isSelected = false
                                        return (
                                          <>
                                            <tr
                                              key={index}
                                              className={
                                                isSelected
                                                  ? classes.tableRowActive
                                                  : ''
                                              }
                                              draggable={!rowLoading}
                                              onDragStart={(event) =>
                                                handleDrag(event, el)
                                              }
                                              onDragEnd={handleDragEnd}
                                              onDragOver={handleDragOver}
                                              onClick={(e) => {
                                                if (
                                                  !rowLoading &&
                                                  !showSuggestions
                                                ) {
                                                  handleOnClickEditor(id)
                                                }
                                              }}
                                              style={{
                                                background: rowLoading
                                                  ? 'inherit'
                                                  : '',
                                                opacity: rowLoading
                                                  ? '0.38'
                                                  : 1,
                                                cursor: rowLoading
                                                  ? 'inherit'
                                                  : 'pointer'
                                              }}
                                            >
                                              {visibleColumns.map((key) => {
                                                return (
                                                  <ResizableCell
                                                    key={key}
                                                    parentWidth={width}
                                                    onResize={(width) =>
                                                      handleResize(key, width)
                                                    }
                                                    width={getColWidth(
                                                      columnWidths[key],
                                                      key
                                                    )}
                                                    id={key}
                                                    isResizing={isResizing}
                                                    setIsResizing={
                                                      setIsResizing
                                                    }
                                                    tableComponent="row"
                                                    isHovering={isHovering}
                                                    setIsHovering={
                                                      setIsHovering
                                                    }
                                                    isMenuHovering={
                                                      isMenuHovering === id
                                                    }
                                                    setIsMenuHovering={
                                                      setIsMenuHovering
                                                    }
                                                  >
                                                    <Box
                                                      className={
                                                        classes.tableRowText
                                                      }
                                                    >
                                                      {renderKeyValues(
                                                        key,
                                                        el[key],
                                                        rowLoading
                                                      )}
                                                    </Box>
                                                  </ResizableCell>
                                                )
                                              })}
                                              <td
                                                className={clsx(
                                                  classes.tableRow,
                                                  classes.menuButton
                                                )}
                                                onMouseEnter={() => {
                                                  setIsHovering(id)
                                                  setIsMenuHovering(false)
                                                }}
                                                onMouseLeave={() => {
                                                  setIsHovering(false)
                                                  setIsMenuHovering(false)
                                                }}
                                              >
                                                <MenuButton
                                                  disabled={rowLoading}
                                                  options={
                                                    showSuggestions
                                                      ? menuOptionsSuggestions
                                                      : menuOptions
                                                  }
                                                  callBackData={el}
                                                  menuOpenCallback={() => {
                                                    setIsMenuHovering(id)
                                                  }}
                                                />
                                              </td>
                                            </tr>
                                          </>
                                        )
                                      })}
                                      {libraryList?.length === 0 &&
                                        !fetchingData &&
                                        !tableLoading && (
                                          <tr className={classes.tableRow}>
                                            <td
                                              colSpan={
                                                visibleColumns.length + 1
                                              }
                                              style={{ cursor: 'default' }}
                                            >
                                              <Box
                                                className={classes.tableRowText}
                                                sx={{ padding: '15px' }}
                                              >
                                                <EmptyRecord
                                                  displayMessage={
                                                    'No knowledge article found'
                                                  }
                                                />
                                              </Box>
                                            </td>
                                          </tr>
                                        )}
                                    </>
                                  )}
                                </tbody>
                              </>
                            )}
                          </ReactResizeDetector>
                        </table>
                      )}
                    </Section>
                  </Container>
                </InfiniteScroll>
              )}
            </Box>
          </Grid>
        </Grid>
        <AddToLibrary
          show={showAddToLibrary}
          setShow={setShowAddToLibrary}
          libraryData={suggestedData}
          onCreateCallback={onCreateCallback}
          redirectOnCreate={true}
        />
        {selectedImages.length > 0 && (
          <ImageGallery
            images={selectedImages}
            onClose={() => setSelectedImages([])}
            options={{ initialViewIndex: selectedImageIndex }}
          />
        )}
      </Section>
      {ConfirmDialog}
      {renderAddToCollections()}
    </Container>
  )
}
export default LibraryList

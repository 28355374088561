import React, { useState, useRef, useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import { useStyles } from './styles'
import _ from 'lodash'
import { checkExpiry } from '../../utils/AWS'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { Loader, ImageGallery, ImageRender } from '../../components'

import MarkdownIt from 'markdown-it'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

import { Card, CardContent, Fade, Menu } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Box from '@material-ui/core/Box'
import ImageIcon from '@mui/icons-material/Image'
import { useColor } from '../../ThemeContext'
import EditNoteIcon from '@mui/icons-material/EditNote'
import { styled } from '@mui/material/styles'

import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import { saveMasterPeopleNotes, saveProjectNotes } from '../../store/api'
import { Button } from '@material-ui/core'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'

import StyleIcon from '@mui/icons-material/Style'
import Drawer from '@mui/material/Drawer'
import TagCenterUpdate from '../TagCenterUpdate'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { updateProjectTag } from '../../store/Project/Actions'
import TextLineLimiter from '../../components/TextLineLimiter'
import { initalizeDownload } from '../../utils/DownloadFromS3/DownloadFromS3'
import DownloadIcon from '@mui/icons-material/Download'
import { checkUserRoleViewer } from '../../utils/User'
import Notebook from '../Notebook/Notebook'
import Button2 from '../../components/Button/Button2'
import { MaximizeIcon, ThreeDotsIcon } from '../../components/Icons/Icons'
import SearchNew from '../ListFilter/SearchNew'

const DisplayTags = (props) => {
  const { tags = [], tagState = {} } = props
  const [tagsObj, setTagsObj] = useState({})
  const classes = useStyles()
  useEffect(() => {
    const tagMap = {}
    tags.forEach((tag) => {
      const valueObj = tagState[tag]
      const { key, value, type } = valueObj || {}
      const val = type?.includes('date') ? moment(value).format('ll') : value
      if (tagMap[key]) {
        tagMap[key] = [...tagMap[key], val]
      } else {
        tagMap[key] = [val]
      }
    })
    setTagsObj(tagMap)
  }, [tags])

  return (
    <Box>
      {Object.keys(tagsObj).map((key, index) => {
        const tagData = tagsObj[key]
        return (
          <Box key={index} className={classes.tagWrapper}>
            <Box className={classes.tagKey}>{key}</Box>
            <Box className={classes.tagValue}>{tagData?.join(', ')}</Box>
          </Box>
        )
      })}
    </Box>
  )
}

const ScrollablePictures = (props) => {
  const { images = [], s3Obj, viewImage = () => {}, type = '' } = props
  const [imagesUrl, setImagesUrl] = useState([])
  const [loading, setLoading] = useState(true)

  const s3Urls = async (images, s3Obj) => {
    const newImagesUrl = []
    setLoading(true)

    try {
      await Promise.all(
        images.map(async (imageUrl) => {
          const signedSrc = await checkExpiry(
            imageUrl?.src ? imageUrl?.src : imageUrl,
            s3Obj
          )
          newImagesUrl.push(signedSrc)
        })
      )
      setImagesUrl(newImagesUrl)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    s3Urls(images, s3Obj)
  }, [images, s3Obj])

  return (
    <Box id="Hori-Scroll-Box" style={{ height: '250px', maxWidth: '100%' }}>
      {loading ? (
        <Loader loading={loading} caption={''} />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '250px',
            overflowX: 'auto',
            maxWidth: '100%',
            overflowY: 'hidden'
          }}
        >
          {imagesUrl.map((image, index) => (
            <div
              key={index}
              style={{
                // position: 'relative',
                marginRight: '10px',
                margin: '8px',
                borderRadius: '10px',
                height: '250px',
                maxWidth: '100%', // Ensure the inner div doesn't exceed the parent's width
                flexShrink: 0
              }}
              onClick={() => viewImage([{ src: image }])}
            >
              <img
                src={image}
                alt={`Image ${index}`}
                style={{
                  width: '100%',
                  maxHeight: '220px',
                  borderRadius: '10px'
                }}
              />
            </div>
          ))}
        </div>
      )}
    </Box>
  )
}

const Text = (props) => {
  const { text, style } = props
  return <span style={style} dangerouslySetInnerHTML={{ __html: text }} />
}

const ProjectResult = (props) => {
  const {
    data = {},
    openProject,
    openPdf,
    currentProject,
    handleCloseProject = () => {},
    s3Obj,
    openType = 'tab',
    thumbnail_url_list = [],
    handleVariationIndexChange = () => {},
    screenshotIndex = 0,
    originProposalNames = [],
    handlePDFViewer = () => {},
    handleNotesUpdate = () => {},
    notesList = [],
    tagState = {},
    projectId = '',
    showBackButton = true
  } = props

  const [selectedImage, setSelectedImage] = useState([])
  const md = new MarkdownIt() // this is used to convert textToHtml
  const [timeline, setTimeline] = useState('')
  const [signedThumbnailList, setSignedThumbnailList] = useState([])
  const { selectedColor } = useColor()
  const [showTagDrawer, setShowTagDrawer] = useState(false)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const isUserViewer = checkUserRoleViewer()

  const gets3Urls = async (images, s3Obj) => {
    const newImagesUrl = []
    try {
      await Promise.all(
        images.map(async (imageUrl) => {
          const signedSrc = await checkExpiry(
            imageUrl?.src ? imageUrl?.src : imageUrl,
            s3Obj
          )
          newImagesUrl.push(signedSrc)
        })
      )
      const thumbnailList = []
      newImagesUrl.forEach((image, index) => {
        thumbnailList.push({
          screenShot: image,
          proposal_name: originProposalNames[index],
          proposal_id: currentProject?.proposal_id,
          page_number: currentProject?.page_number
        })
      })
      setSignedThumbnailList(thumbnailList)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (thumbnail_url_list.length > 0) {
      gets3Urls(thumbnail_url_list, s3Obj)
    } else {
      setLoading(false)
    }
  }, [thumbnail_url_list, s3Obj])

  const preprocessMarkdown = (markdownText) => {
    let format = markdownText.replace(/<br>/g, '  \n')
    format = format.replace(/\*/g, '\n+')
    format = format.replace(/<mark>/g, '')
    format = format.replace(/<\/mark>/g, '')
    return format
  }

  const convertToHTML = (markdownText) => {
    markdownText = markdownText || ' '
    const preprocessedText = preprocessMarkdown(markdownText)
    return md.render(preprocessedText)
  }

  const VariationIndexChange = (index) => {
    handleVariationIndexChange(index - 1)
  }

  const {
    project_name = '',
    project_location = '',
    project_description = '',
    images = [],
    project_timeline = {},
    project_team_members = {},
    square_footage = '',
    total_project_cost = ''
  } = data

  const [editNotes, setEditNotes] = useState(false)
  const [editedNote, setEditedNote] = useState('')
  const [tags, setTags] = useState([])
  const [prevNote, setPrevNote] = useState('')
  const handleSaveNotes = async () => {
    const saveData = {
      project_id: projectId,
      notes: editedNote
    }
    setEditNotes(false)
    const res = await saveProjectNotes(saveData)
    if (res.status === 200) {
      handleNotesUpdate(editedNote, projectId)
    }
  }

  const handleCancelNotes = () => {
    setEditedNote(prevNote)
    setEditNotes(false)
  }

  useEffect(() => {
    const item = notesList.filter((note) => note.id === projectId)[0]
    const notes = item?.notes
    const tags = item?.tags
    setEditedNote(notes)
    setPrevNote(notes)
    setTags(tags)
  }, [notesList])

  useEffect(() => {
    if (typeof project_timeline === 'string') {
      setTimeline(project_timeline)
    } else {
      const line =
        project_timeline?.start_date +
        (project_timeline?.end_date ? ' - ' + project_timeline?.end_date : '')
      setTimeline(line)
    }
  }, [project_timeline])

  const renderObjectProperties = (object) => {
    return Object.keys(object).map((key) => (
      <div key={key}>
        {object?.[key] && (
          <>
            <Text
              text={_.startCase(key) + ' - '}
              style={{ fontWeight: 'bold' }}
            />
            {typeof object[key] === 'object' ? (
              renderObjectProperties(object[key])
            ) : (
              <Text text={object[key] + '<br/>'} />
            )}
          </>
        )}
      </div>
    ))
  }

  const renderKeyTeamMembers = (teamMembers) => {
    if (typeof teamMembers === 'string') {
      return (
        <div>
          <Text text={teamMembers + '<br/>'} />
        </div>
      )
    }
    return teamMembers?.map((member, index) => (
      <div key={index}>
        <Text
          text={
            (member?.name ? member?.name : '') +
            (member?.role ? ', ' + member?.role : '') +
            (member?.responsibilities ? ', ' + member?.responsibilities : '') +
            '<br/>'
          }
        />
      </div>
    ))
  }
  const downloadImage = async (payload) => {
    const { proposalName, screenShot } = payload
    const name = proposalName
      ? proposalName.split('.').slice(0, -1).join('.')
      : 'image'
    await initalizeDownload(screenShot, name + '.png', s3Obj)
  }

  const viewImage = (image) => {
    setSelectedImage(image)
  }

  const renderTagDrawer = () => {
    const id = projectId
    const document_type = 'project'
    return (
      id && (
        <Drawer
          anchor={'right'}
          open={showTagDrawer}
          onClose={() => setShowTagDrawer(false)}
        >
          <Box sx={{ padding: '20px', width: '400px' }}>
            <TagCenterUpdate
              id={id}
              filterTags="project"
              document_type={document_type}
              onUpdateCallback={onUpdateCallback}
              cancelCallback={() => setShowTagDrawer(false)}
            />
          </Box>
        </Drawer>
      )
    )
  }

  const onUpdateCallback = (id, mode, tagsFormated) => {
    if (mode === 'tags') {
      const newTags = []
      tagsFormated.forEach((tag) => {
        newTags.push(tag.id)
      })
      const payload = {
        id,
        tags: newTags
      }
      const callback = () => {
        setTags(newTags)
      }
      dispatch(updateProjectTag(payload, callback))
    }
  }

  const basicInfo = {
    name: project_name,
    logo: ''
  }

  const isSuperUser = false
  const [activeTab, setActiveTab] = useState(0)
  const [notesData, setNotesData] = useState(currentProject.notes ?? [])
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredProjects, setFilteredProjects] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return openType === 'search_results' ? (
    <>
      <Dialog
        open={openProject}
        onClose={handleCloseProject}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          <span dangerouslySetInnerHTML={{ __html: project_name }} />
        </DialogTitle>
        <DialogContent>
          {images?.length > 0 && (
            <ScrollablePictures
              images={images}
              s3Obj={s3Obj}
              type={'view'}
              viewImage={viewImage}
            />
          )}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '30% 70%',
              gap: '0px',
              gridAutoFlow: 'row'
            }}
          >
            <div style={{ gridColumn: '1', margin: '5px' }}>
              {project_location && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text text={'Location : '} style={{ fontWeight: 'bold' }} />
                  <Text
                    text={project_location + '<br/>'}
                    style={{ fontStyle: 'italic' }}
                  />
                </Box>
              )}
              {total_project_cost && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text text={'Budget : '} style={{ fontWeight: 'bold' }} />
                  <Text
                    text={total_project_cost + '<br/>'}
                    style={{ fontStyle: 'italic' }}
                  />
                </Box>
              )}
              {square_footage && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text text={'Area : '} style={{ fontWeight: 'bold' }} />
                  <Text
                    text={square_footage + '<br/>'}
                    style={{ fontStyle: 'italic' }}
                  />
                </Box>
              )}
              {timeline !== '' && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text text={'Duration : '} style={{ fontWeight: 'bold' }} />
                  <Text
                    text={timeline + '<br/>'}
                    style={{ fontStyle: 'italic' }}
                  />
                </Box>
              )}
              {project_team_members.length > 0 && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text
                    text={'Key Team Members -'}
                    style={{ fontWeight: 'bold' }}
                  />
                  {renderKeyTeamMembers(project_team_members)}
                </Box>
              )}
            </div>
            <div style={{ gridColumn: '2', margin: '5px', overflow: 'auto' }}>
              {project_description && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text
                    text={'About the project -  <br/>'}
                    style={{ fontWeight: 'bold' }}
                  />
                  <Text text={convertToHTML(project_description)} />
                </Box>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{ position: 'absolute', top: '10px', right: '10px' }}
        >
          <IconButton onClick={handleCloseProject}>
            <CloseIcon sx={{}} />
          </IconButton>
        </DialogActions>
      </Dialog>
      {selectedImage.length > 0 && (
        <ImageGallery
          images={selectedImage}
          onClose={() => setSelectedImage([])}
          s3Obj={s3Obj}
        />
      )}
    </>
  ) : loading ? (
    <Loader loading={loading} caption={''} flex />
  ) : (
    <>
      <div className="w-full h-full">
        <div className="h-full">
          <div
            style={{
              width: '70%',
              margin: 'auto',
              padding: '20px',
              paddingBottom: '0px'
            }}
          >
            <div className="flex gap-2 items-center text-xxs text-grey-500 mb-3">
              <span className="cursor-pointer" onClick={handleCloseProject}>
                Project
              </span>{' '}
              <span> {'>'} </span>{' '}
              <span className="text-grey-700 text-capitalize w-44 text-truncate">
                {basicInfo.name}
              </span>
            </div>
            <div className="flex flex-col">
              <div className="flex gap-2 items-center">
                <div className="">
                  <p className="m-0 text-lg font-medium leading-5 flex items-center gap-1 text-capitalize">
                    {basicInfo.name}
                  </p>
                </div>
                {isSuperUser && (
                  <Button2
                    onClick={showResumeEditor}
                    secondary
                    style={{
                      marginLeft: 'auto'
                    }}
                  >
                    Resume Editor
                  </Button2>
                )}
              </div>
            </div>
            <div className="flex mt-3">
              <p
                className="m-0 font-medium text-sm p-1 px-3 text-grey-700 cursor-pointer"
                onClick={() => setActiveTab(0)}
                style={{
                  borderBottom: activeTab === 0 && '2px solid var(--grey-700)'
                }}
              >
                Projects
              </p>
              <p
                className="m-0 font-medium text-sm p-1 px-3 text-grey-700 cursor-pointer"
                onClick={() => setActiveTab(1)}
                style={{
                  borderBottom: activeTab === 1 && '2px solid var(--grey-700)'
                }}
              >
                Notes
              </p>
            </div>
          </div>

          <div
            className="bg-grey-100 p-4"
            style={{
              height: 'calc(100% - 200px)'
            }}
          >
            <div
              className="rounded-lg"
              style={{
                width: '70%',
                margin: 'auto',
                height: 'calc(100% - 20px)',
                overflowY: 'auto',
                backgroundColor: 'white',
                padding: '0 20px'
              }}
            >
              {activeTab === 0 && (
                <div
                  style={{
                    padding: '10px 20px'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                      gap: '6px',
                      padding: '8px 0px',
                      marginBottom: '5px'
                    }}
                  >
                    <SearchNew
                      value={searchQuery}
                      onChange={(value) => {
                        handleSearch(value)
                      }}
                      placeholder="Search Docs"
                      onClear={() => {
                        handleSearch('')
                      }}
                    />
                  </div>
                  <div className="">
                    <div className="grid grid-col-5 gap-4">
                      {signedThumbnailList?.map((project, index) => {
                        const {
                          proposal_id,
                          thumbnail_url = '',
                          screenshots = [],
                          page_number = 1,
                          proposal_name = '',
                          version = '',
                          screenShot
                        } = project
                        const coverImage = _.isEmpty(screenshots)
                          ? thumbnail_url
                          : screenshots[0]?.src
                        const imagePayload = _.isEmpty(screenshots)
                          ? thumbnail_url
                          : screenshots
                        const displayName = proposal_name || proposal_id

                        return (
                          <Box
                            key={index}
                            className="parent-card"
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              border: '1px solid var(--grey-200)',
                              overflow: 'hidden',
                              borderRadius: '10px',
                              '&:hover img': {
                                opacity: '0.5'
                              },
                              '&:hover #overlayMenu': {
                                opacity: '0.8 !important'
                              }
                            }}
                          >
                            <Box
                              id={'project_' + index}
                              key={'project_' + index}
                              style={{
                                padding: '10px',
                                position: 'relative'
                              }}
                            >
                              <Box
                                sx={{
                                  justifyContent: 'center',
                                  width: '100%',
                                  display: 'flex',
                                  cursor: 'pointer'
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <ImageRender
                                    style={{
                                      height: '100%',
                                      width: '100%',
                                      minWidth: '180px',
                                      maxWidth: '200px',
                                      minHeight: '220px',
                                      objectFit: 'contain',
                                      overflow: 'hidden',
                                      transition: 'opacity 0.5s ease'
                                    }}
                                    src={screenShot}
                                    s3Obj={s3Obj}
                                    effect="blur"
                                    overlay={
                                      <Box
                                        id="overlayMenu"
                                        style={{
                                          position: 'absolute',
                                          top: '0%',
                                          right: '0%',
                                          opacity: 0,
                                          backgroundColor: 'var(--grey-700)',
                                          padding: '8px',
                                          width: '100%',
                                          height: '100%',
                                          boxSizing: 'border-box',
                                          zIndex: 1000,
                                          transition: 'opacity 0.3s ease'
                                        }}
                                      >
                                        <div className="relative h-full">
                                          {screenShot && (
                                            <div
                                              className="absolute"
                                              style={{
                                                top: '45%',
                                                left: '45%'
                                              }}
                                              onClick={() => {
                                                viewImage([{ src: screenShot }])
                                              }}
                                            >
                                              <MaximizeIcon className="size-6 text-grey-100" />
                                            </div>
                                          )}
                                        </div>
                                      </Box>
                                    }
                                    fallback={
                                      <>
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            wordBreak: 'break-word'
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              fontWeight: '600',
                                              fontSize: '16px',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              '-webkit-line-clamp': 2,
                                              display: '-webkit-box',
                                              '-webkit-box-orient': 'vertical'
                                            }}
                                          >
                                            {displayName}
                                          </Box>
                                        </Box>
                                      </>
                                    }
                                  />
                                </Box>
                              </Box>
                            </Box>
                            <div className="bg-grey-100 p-3 flex justify-between items-center">
                              {proposal_name ? (
                                <Tooltip title={proposal_name}>
                                  <p
                                    className="m-0 font-medium text-xs cursor-pointer"
                                    style={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap'
                                    }}
                                    onClick={() => {
                                      openPdf(proposal_id, page_number)
                                    }}
                                  >
                                    {proposal_name}
                                  </p>
                                </Tooltip>
                              ) : (
                                <p className="m-0 font-medium text-sm">
                                  Untitled Project
                                </p>
                              )}

                              <div className="relative h-full w-16 card-options">
                                <div className="text-grey-700 rounded-md flex justify-end">
                                  <span
                                    onClick={handleClick}
                                    className="cursor-pointer grid place-content-center"
                                  >
                                    <ThreeDotsIcon
                                      className="size-5"
                                      strokeWidth={2}
                                    />
                                  </span>
                                  <Menu
                                    elevation={2}
                                    TransitionComponent={Fade}
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleCloseMenu}
                                    MenuListProps={{
                                      'aria-labelledby': 'basic-button'
                                    }}
                                    className="p-1"
                                  >
                                    <Button2
                                      noOutline
                                      secondary
                                      style={{
                                        width: '100%'
                                      }}
                                      onClick={() =>
                                        handleDownloadCustomPdf(
                                          proposal_id,
                                          page_number
                                        )
                                      }
                                    >
                                      Download Resume
                                    </Button2>
                                    <Button2
                                      noOutline
                                      secondary
                                      style={{
                                        width: '100%'
                                      }}
                                      onClick={() => handleMove(resume)}
                                    >
                                      Move Resume
                                    </Button2>
                                    <Button2
                                      noOutline
                                      secondary
                                      style={{
                                        width: '100%'
                                      }}
                                      onClick={() => {
                                        handleMasterMappingDelete(
                                          people,
                                          index,
                                          'delete'
                                        )
                                      }}
                                    >
                                      Remove Resume
                                    </Button2>
                                  </Menu>
                                </div>
                              </div>
                            </div>
                          </Box>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 1 && <Notebook notesData={notesData} />}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '70%',
          margin: 'auto',
          padding: '20px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '16px',
            marginTop: '10px'
          }}
        >
          {signedThumbnailList?.map((project, index) => {
            return (
              <Box
                sx={{ padding: '10px' }}
                id={'project_' + index}
                key={'project_' + index}
              >
                <Box
                  onClick={() =>
                    screenshotIndex !== index && VariationIndexChange(index)
                  }
                  sx={{
                    justifyContent: 'center',
                    height: '140px',
                    width: '110px',
                    border: '2px solid',
                    borderColor: screenshotIndex === index ? 'red' : '#E5E5E5',
                    padding: '2px',
                    display: 'flex',
                    position: 'relative',
                    cursor: 'pointer',
                    '& .overlayButtons': {
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: 'inherit',
                      width: '100%'
                    },
                    '&:hover img': {
                      opacity: '0.5'
                    },
                    '&:hover .overlayButtons': {
                      opacity: '1 !important'
                    }
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <ImageRender
                      style={{
                        height: '100%',
                        width: '100%',
                        textAlign: 'center',
                        objectFit: 'cover',
                        color: 'transparent',
                        textIndent: '10000px',
                        marginTop: '5px'
                      }}
                      src={project?.screenShot}
                      s3Obj={s3Obj}
                      showLoader={false}
                      effect="blur"
                      overlay={
                        <Box className="overlayButtons">
                          {project.screenShot && (
                            <>
                              <Tooltip title="View Screenshot">
                                <IconButton
                                  disableRipple
                                  onClick={() =>
                                    viewImage([{ src: project.screenShot }])
                                  }
                                >
                                  <ImageIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Download Screenshot">
                                <IconButton
                                  disableRipple
                                  onClick={() => downloadImage(project)}
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </Box>
                      }
                      fallback={
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            wordBreak: 'break-word'
                          }}
                        >
                          <Box sx={{ fontWeight: '600', fontSize: '16px' }}>
                            {project?.proposalName}
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
                {/* <Box sx={{ width: '110px', wordBreak: 'break-word', fontWeight: '600', fontSize: '16px', marginTop: '10px', cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }} onClick={() => handlePDFViewer(index)}>{project.proposalName}</Box> */}
                <Tooltip title={project.proposalName}>
                  <Box
                    sx={{
                      width: '110px',
                      wordBreak: 'break-word',
                      fontWeight: '600',
                      fontSize: '16px',
                      marginTop: '10px',
                      cursor: 'pointer',
                      '&:hover': { textDecoration: 'underline' }
                    }}
                    onClick={() => handlePDFViewer(index)}
                  >
                    <TextLineLimiter
                      content={project.proposalName}
                      limit={2}
                      showMore={false}
                    />
                  </Box>
                </Tooltip>
              </Box>
            )
          })}
        </Box>
        {openProject && (
          <>
            <Card
              sx={{
                border: '1px solid #E5E5E5'
              }}
            >
              <CardContent>
                <Text
                  text={project_name + '<br/>'}
                  style={{ fontWeight: 'bold', fontSize: '22px' }}
                />
                {images?.length > 0 && (
                  <ScrollablePictures
                    images={images}
                    s3Obj={s3Obj}
                    type={'view'}
                    viewImage={viewImage}
                  />
                )}
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '30% 70%',
                    gap: '0px',
                    gridAutoFlow: 'row'
                  }}
                >
                  <div style={{ gridColumn: '1', margin: '5px' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '15px',
                        padding: '10px 0px'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginTop: '15px',
                          padding: '10px 0px'
                        }}
                      >
                        <Box
                          sx={{
                            fontSize: '20px',
                            fontWeight: '600'
                          }}
                        >
                          Tags
                          <Tooltip title="Add Tags">
                            <IconButton onClick={() => setShowTagDrawer(true)}>
                              <StyleIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        {tags?.length > 0 && (
                          <DisplayTags tags={tags} tagState={tagState} />
                        )}
                      </Box>
                    </Box>
                    {project_location && (
                      <Box
                        sx={{
                          marginTop: '8px'
                        }}
                      >
                        <Text
                          text={'Location : '}
                          style={{ fontWeight: 'bold' }}
                        />
                        <Text
                          text={project_location + '<br/>'}
                          style={{ fontStyle: 'italic' }}
                        />
                      </Box>
                    )}
                    {total_project_cost && (
                      <Box
                        sx={{
                          marginTop: '8px'
                        }}
                      >
                        <Text
                          text={'Budget : '}
                          style={{ fontWeight: 'bold' }}
                        />
                        <Text
                          text={total_project_cost + '<br/>'}
                          style={{ fontStyle: 'italic' }}
                        />
                      </Box>
                    )}
                    {square_footage && (
                      <Box
                        sx={{
                          marginTop: '8px'
                        }}
                      >
                        <Text text={'Area : '} style={{ fontWeight: 'bold' }} />
                        <Text
                          text={square_footage + '<br/>'}
                          style={{ fontStyle: 'italic' }}
                        />
                      </Box>
                    )}
                    {timeline !== '' && (
                      <Box
                        sx={{
                          marginTop: '8px'
                        }}
                      >
                        <Text
                          text={'Duration : '}
                          style={{ fontWeight: 'bold' }}
                        />
                        <Text
                          text={timeline + '<br/>'}
                          style={{ fontStyle: 'italic' }}
                        />
                      </Box>
                    )}
                    {project_team_members.length > 0 && (
                      <Box
                        sx={{
                          marginTop: '8px'
                        }}
                      >
                        <Text
                          text={'Key Team Members -'}
                          style={{ fontWeight: 'bold' }}
                        />
                        {renderKeyTeamMembers(project_team_members)}
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '12px'
                      }}
                    >
                      <Text
                        text={'Notes <br/>'}
                        style={{ fontWeight: 'bold' }}
                      />
                      {editNotes ? (
                        <>
                          <IconButton disableRipple onClick={handleSaveNotes}>
                            <DoneIcon />
                          </IconButton>
                          <IconButton disableRipple onClick={handleCancelNotes}>
                            <CloseIcon />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton
                          onClick={() => setEditNotes(true)}
                          disabled={isUserViewer}
                        >
                          <EditNoteIcon />
                        </IconButton>
                      )}
                    </Box>
                    {editNotes ? (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                          paddingRight: '10px'
                        }}
                      >
                        <TextareaAutosize
                          minRows={3}
                          maxRows={4}
                          aria-label="maximum height"
                          placeholder=""
                          defaultValue={editedNote}
                          onChange={(e) => setEditedNote(e.target.value)}
                          style={{
                            width: '100%',
                            fontFamily: 'inherit',
                            fontSize: 'inherit',
                            padding: '5px',
                            border: '1px solid #ccc',
                            borderRadius: '4px'
                          }}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          marginTop: '8px'
                        }}
                      >
                        <Text text={editedNote} />
                      </Box>
                    )}
                  </div>
                  <div
                    style={{ gridColumn: '2', margin: '5px', overflow: 'auto' }}
                  >
                    {project_description && (
                      <Box
                        sx={{
                          marginTop: '8px'
                        }}
                      >
                        <Text
                          text={'About the project -  <br/>'}
                          style={{ fontWeight: 'bold' }}
                        />
                        <Text text={convertToHTML(project_description)} />
                      </Box>
                    )}
                  </div>
                </div>
              </CardContent>
            </Card>
          </>
        )}
      </div>
      {selectedImage.length > 0 && (
        <ImageGallery
          images={selectedImage}
          onClose={() => setSelectedImage([])}
          s3Obj={s3Obj}
        />
      )}
      {renderTagDrawer()}
    </>
  )
}

export default ProjectResult

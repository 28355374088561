import { Box, Dialog, Fade, Menu } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LexicalEditor from '../../components/Lexical'
import { $generateHtmlFromNodes } from '@lexical/html'
import Button2 from '../../components/Button/Button2'
import {
  NoteAddIcon,
  PlusIcon,
  SemiCircleIcon,
  ThreeDotsIcon,
  TrashIcon
} from '../../components/Icons/Icons'
import {
  createLibraryDocument,
  deleteLibraryDocument,
  updateLibraryDocument
} from '../../store/Library/Actions'
import { useDispatch } from 'react-redux'
import { autocompleteMention } from '../../store/api'
import { getColorsForInitials } from '../../utils/String'
import { formatTimeAgo } from '../../utils/Date'
import { updateNotes } from '../../store/Library/Actions/UpdateLibraryDocument/UpdateLibraryDocument'

const Notebook = ({ notesData, source, refresh = () => {} }) => {
  // const [editedHtmlString, setEditedHtmlString] = useState(
  //   `<h1 class="PlaygroundEditorTheme__h1"><span>Introducing Notebooks! 🥳     </span></h1><p class="PlaygroundEditorTheme__paragraph" dir="ltr"><span>Notebooks are a powerful way to collate, analyze, and share Joist         insights with others.       </span></p><ul class="PlaygroundEditorTheme__ul"><li value="1" class="PlaygroundEditorTheme__listItem"><span>Preparing for Proposal </span></li><li value="2" class="PlaygroundEditorTheme__listItem"><span>Winning Strategy </span></li></ul><p class="PlaygroundEditorTheme__paragraph"><span> </span></p><p class="PlaygroundEditorTheme__paragraph" dir="ltr"><span>There’s no limit to how many notebooks you can create, or how you can         share them within your organisation.</span></p>`
  // )
  console.log('notes', notesData)
  const [currentEditorState, setCurrentEditorState] = useState({})
  const [editedHtmlString, setEditedHtmlString] = useState(`<h1>Testigng</h1>`)
  const [unSavedChanges, setUnSavedChanges] = useState(false)
  const dispatch = useDispatch()
  const [notes, setNotes] = useState(notesData)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [selectedNote, setSelectedNote] = useState(null)
  const [noteLoading, setNoteLoading] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [showCreateNote, setShowCreateNote] = useState(false)
  const [saving, setSaving] = useState(false)
  // useEffect(() => {
  //   if (unSavedChanges) {
  //     const timeout = setTimeout(() => {
  //       handleSave()
  //     }, 3000)

  //     return () => clearTimeout(timeout)
  //   }
  //   console.log('currentEditorState 2', currentEditorState)
  // }, [currentEditorState])

  useEffect(() => {
    console.log('selectedNote loading', selectedNote)
    if (selectedNote) {
      setTimeout(() => {
        setNoteLoading(false)
      }, 500)
    }
  }, [selectedNote])

  useEffect(() => {
    if (notesData.length > 0 && !selectedNote) {
      setSelectedNote(notesData[0])
    }
  }, [notesData])

  const handleCreate = (name) => {
    console.log('selectedNote save', {
      document_content: {},
      document_html: '',
      document_name: name,
      mentioned_documents: []
    })

    dispatch(
      createLibraryDocument(
        {
          document_content: {},
          document_html: '',
          document_name: name,
          mentioned_documents: [source]
        },
        (response) => {
          setNotes((prev) => {
            return [...prev, response.document]
          })
          setNoteLoading(true)
          setSelectedNote(response.document)
          setShowCreateNote(false)
        }
      )
    )
  }

  const handleSave = () => {
    setSaving(true)
    console.log('selectedNote save', {
      id: selectedNote.id,
      document_content: currentEditorState,
      document_html: editedHtmlString,
      document_name: selectedNote.title,
      mentioned_documents: selectedNote.mentioned_documents
    })

    dispatch(
      updateNotes(
        {
          id: selectedNote.id,
          document_content: currentEditorState,
          document_html: editedHtmlString,
          document_name: selectedNote.title,
          mentioned_documents: selectedNote.mentioned_documents
        },
        (response) => {
          //
          setSaving(false)
          setNotes((prev) => {
            return prev.map((note) => {
              if (note.id === selectedNote.id) {
                return {
                  ...note,
                  // content: editor.toHTML(),
                  mentioned_documents: selectedNote.mentioned_documents,
                  document_content: currentEditorState
                }
              }
              return note
            })
          })
          refresh()
          setUnSavedChanges(false)
        }
      )
    )
  }

  const handleEditorChange = (currentEditorState, editor) => {
    editor.update(() => {
      console.log(
        'currentEditorState',
        currentEditorState,
        'selectedNote.document_content',
        selectedNote.document_content
      )
      if (
        JSON.stringify(currentEditorState) ===
        JSON.stringify(selectedNote.document_content)
      ) {
        setUnSavedChanges(false)
        return
      }

      setUnSavedChanges(true)
      // list MentionNodes
      const docs = selectedNote.mentioned_documents ?? [
        {
          id: source.id,
          type: source.type,
          name: source.name
        }
      ]
      currentEditorState?._nodeMap?.forEach((node) => {
        if (node?.__type === 'mention' && node?.__id) {
          const mention = {
            id: node.__id,
            type: node.__mentionType,
            name: node.__text
          }

          if (!docs.some((doc) => doc.id === mention.id)) {
            docs.push(mention)
          }
        }
      })

      setCurrentEditorState(currentEditorState)

      const htmlString = $generateHtmlFromNodes(editor, null)
      setEditedHtmlString(htmlString)

      setSelectedNote((prev) => {
        return {
          ...prev,
          document_html: htmlString,
          // document_content: currentEditorState,
          mentioned_documents: docs
        }
      })
    })
  }

  const handleDeleteConfirmation = () => {
    setAnchorEl(null)
    setShowDeleteConfirmation(true)
  }

  const handleDelete = () => {
    dispatch(
      deleteLibraryDocument({ id: selectedNote.id }, () => {
        refresh()
        setShowDeleteConfirmation(false)
        const newList = notes.filter((note) => note.id !== selectedNote.id)
        setNotes(newList)
        if (newList.length > 0) {
          setNoteLoading(true)

          setSelectedNote(newList[0])
        } else {
          setNoteLoading(true)

          setSelectedNote(null)
        }
      })
    )
  }

  if (notes.length === 0) {
    return (
      <div className="grid place-content-center h-full text-center">
        <p className="text-lg text-grey-700 m-0 text-center font-medium">
          No notes
        </p>

        <p className="text-sm text-grey-500 m-0 text-center">
          Create a note to get started
        </p>

        <Button2
          primary
          onClick={() => setShowCreateNote(true)}
          style={{
            width: 'fit-content',
            margin: 'auto',
            padding: '6px 15px',
            marginTop: '10px'
          }}
        >
          <NoteAddIcon className="size-4" />
          Create note
        </Button2>

        <CreateNotebookDocument
          onCreate={handleCreate}
          show={showCreateNote}
          onClose={() => {
            setShowCreateNote(false)
          }}
        />
      </div>
    )
  }

  return (
    <div className="flex h-full">
      <div
        className="flex flex-col gap-1"
        style={{
          width: '30%',
          borderRight: '1px solid var(--grey-200)'
        }}
      >
        <div className="flex justify-between items-center p-2 border-b-1">
          <p className="text-sm font-medium m-0">All Notes</p>
          <Button2
            secondary
            noOutline
            onClick={() => {
              setShowCreateNote(true)
            }}
            style={{
              padding: '4px'
            }}
          >
            <PlusIcon className="size-4" />
          </Button2>
        </div>
        <div
          style={{
            padding: '6px'
          }}
        >
          {notes.map((note) => {
            return (
              <NoteTab
                key={note.id}
                note={note}
                selected={selectedNote}
                setSelected={(note) => {
                  setNoteLoading(true)
                  setSaving(false)
                  setSelectedNote(note)
                }}
              />
            )
          })}
        </div>
      </div>
      {noteLoading ? (
        <div
          className="grid place-content-center h-full"
          style={{
            width: '70%',
            padding: '0 15px',
            boxsizing: 'border-box'
          }}
        >
          <SemiCircleIcon className="size-5 animate-spin" />
        </div>
      ) : (
        selectedNote && (
          <Box
            id="notes-editor"
            style={{
              height: 'calc(100% - 50px)',
              width: '70%',
              padding: '0 15px',
              boxsizing: 'border-box'
            }}
          >
            <div className="flex justify-between items-center">
              <input
                type="text"
                className="input-base text-lg font-medium"
                style={{
                  padding: '10px 4px'
                }}
                placeholder="Enter note title"
                onChange={(e) => {
                  setSelectedNote({
                    ...selectedNote,
                    document_name: e.target.value
                  })
                }}
                value={selectedNote.document_name}
              />
              <div className="flex gap-2">
                <Button2
                  secondary
                  onClick={handleSave}
                  disabled={saving}
                  style={{
                    padding: '4px 8px',
                    fontSize: '12px'
                  }}
                >
                  {saving ? (
                    <SemiCircleIcon className="size-4 animate-spin" />
                  ) : (
                    'Save'
                  )}
                </Button2>
                <Button2
                  secondary
                  noOutline
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget)
                  }}
                  style={{
                    padding: '4px'
                  }}
                >
                  <ThreeDotsIcon className="size-4" />
                </Button2>

                <Menu
                  elevation={0}
                  TransitionComponent={Fade}
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                  sx={{
                    '& .MuiMenu-list': {
                      padding: '4px 6px'
                    },
                    '& .MuiMenu-paper': {
                      boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)'
                    }
                  }}
                  // className="p-1 px-2"
                  style={{
                    padding: '4px'
                  }}
                >
                  <Button2
                    noOutline
                    secondary
                    style={{
                      width: '100%',
                      padding: '4px 8px',
                      color: '#ff4747'
                    }}
                    onClick={handleDeleteConfirmation}
                  >
                    <TrashIcon className="size-4" /> Delete note
                  </Button2>
                </Menu>
              </div>
            </div>
            <LexicalEditor
              miniEditor={true}
              initialEditorContent={
                !_.isEmpty(selectedNote?.document_content)
                  ? selectedNote?.document_content
                  : {}
              }
              unSavedChanges={unSavedChanges}
              // setUnSavedChanges={}
              handleEditorChange={handleEditorChange}
              key={selectedNote}
            />
            <div className="text-xxs text-grey-600 flex items-center gap-1 mt-3">
              <div
                className="flex items-center justify-center size-4 rounded-full text-xxs text-white mr-1"
                style={{
                  backgroundColor: getColorsForInitials(
                    selectedNote.contributors[0].name
                  )
                }}
              >
                {selectedNote.contributors[0].name[0]}
              </div>{' '}
              {selectedNote.contributors[0].name} created this note{' '}
              <span className="size-1 rounded-full bg-grey-300"></span>
              {formatTimeAgo(selectedNote.updated_at)}
            </div>
          </Box>
        )
      )}

      <CreateNotebookDocument
        onCreate={handleCreate}
        show={showCreateNote}
        onClose={() => {
          setShowCreateNote(false)
        }}
      />
      <DeleteNotebookDocument
        show={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={handleDelete}
        confirmationMessageTitle="Are you sure you want to delete this note?"
        confirmationMessage="This action cannot be undone."
        key={showDeleteConfirmation}
      />
    </div>
  )
}

export default Notebook

const CreateNotebookDocument = ({ onCreate, show, onClose }) => {
  const [noteName, setNoteName] = useState('New Note')

  return (
    <Dialog open={show} onClose={onClose}>
      <div className="p-3 px-4 w-72">
        {/* <p className="m-0 text-sm">Create Note</p> */}
        <div className="flex flex-col justify-end gap-3">
          <div>
            <label className="text-xxs text-grey-600">Name</label>
            <input
              type="text"
              className="input-base p-2 border-1 border-grey-200 rounded-lg w-full text-sm"
              style={{
                boxSizing: 'border-box'
              }}
              placeholder="Enter document name"
              onChange={(e) => {
                setNoteName(e.target.value)
              }}
              value={noteName}
            />
          </div>
          <div className="flex justify-end gap-1">
            <Button2
              secondary
              noOutline
              onClick={onClose}
              style={{
                marginLeft: 'auto'
              }}
            >
              Cancel
            </Button2>
            <Button2 primary onClick={() => onCreate(noteName)}>
              Create
            </Button2>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const DeleteNotebookDocument = ({
  show,
  onClose,
  onConfirm,
  confirmationMessageTitle,
  confirmationMessage
}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [])

  return (
    <Dialog open={show} onClose={onClose}>
      <div className="p-3 px-4 w-84">
        <p className="m-0 text-xs font-medium">{confirmationMessageTitle}</p>
        <p className="m-0 text-xxs text-grey-600 mt-1">{confirmationMessage}</p>
        <div className="flex justify-end gap-1 mt-3">
          <Button2 secondary noOutline onClick={onClose}>
            Cancel
          </Button2>
          <Button2
            secondary
            noOutline
            onClick={() => {
              setLoading(true)
              onConfirm()
            }}
            style={{
              backgroundColor: '#ef4444',
              color: 'white'
            }}
            disabled={loading}
          >
            {loading ? (
              <SemiCircleIcon className="size-4 animate-spin" />
            ) : (
              'Delete'
            )}
          </Button2>
        </div>
      </div>
    </Dialog>
  )
}

const NoteTab = ({ note, selected, setSelected }) => {
  return (
    <div
      key={note.id}
      className="cursor-pointer p-2 px-4 rounded-lg flex justify-between items-start hover-bg-grey-100"
      style={{
        backgroundColor: selected?.id === note.id ? 'var(--grey-100)' : ''
      }}
      onClick={() => {
        setSelected(note)
      }}
    >
      <p className="text-xxs m-0 font-medium w-32">{note.document_name}</p>

      {/* add time with time and yesterday and date */}
      <p
        className="text-xxs m-0 text-grey-600"
        style={{
          fontSize: '9px'
        }}
      >
        {formatTimeAgo(note.updated_at, true)}
      </p>
    </div>
  )
}
